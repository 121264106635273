import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


const resources = {
  en: {
    translation: {
      amount: 'Amount',
      credit_limit: 'Credit Limit',
      cash_withdrawal: 'Cash Withdrawal',
      interest_rate: 'Interest Rate',
      age: 'Age',
      annual_income: 'Annual Income',
      term: 'Term',
      maintenance: 'Maintenance',
      interest_free_period: 'Interest-Free Days',
      down_payment: 'Down Payment',
      legal_entity_name: 'Legal Entity Name',
      license_number: 'License Number',
      inn: 'TIN',
      ogrn: 'PSRN',
      legal_address: 'Legal Address',
      total_loan_cost: 'Total Loan Cost',
      max_loan_term: 'Maximum Loan Term',
      cookieWarning: `By continuing to use our website, you consent to the processing of cookies and user data
(location information; OS type and version; browser type and version; device type and screen resolution;
the source from which the user came to the site; the website or advertisement; OS and browser language;
the pages opened and buttons clicked by the user; IP address) for site functionality,
retargeting, and statistical research. If you do not wish for your data
to be processed, please leave the site.`,
      cookieAcceptButton: 'Accept',
      creditorInfoHeader: 'Creditor Information',
      documentationSource: 'Detailed Information',
      bankButton: 'Apply',
      advertising: 'Advertising',
      rangeFrom: 'from',
      rangeTo: 'to',
      days_one: 'day',
      days_other: 'days',
      weeks_one: 'week',
      weeks_other: 'weeks',
      months_one: 'month',
      months_other: 'months',
      years_one: 'year',
      years_other: 'years',
      of_days_one: 'day',
      of_days_other: 'days',
      of_weeks_one: 'week',
      of_weeks_other: 'weeks',
      of_months_one: 'month',
      of_months_other: 'months',
      of_years_one: 'year',
      of_years_other: 'years',
    }
  },
  ru: {
    translation: {
      amount: 'Сумма',
      credit_limit: 'Кредитный лимит',
      cash_withdrawal: 'Снятие наличных',
      interest_rate: 'Ставка',
      age: 'Возраст',
      annual_income: 'Доход (годовых)',
      term: 'Срок',
      maintenance: 'Обслуживание',
      interest_free_period: 'Дней без %',
      down_payment: 'Первоначальный взнос',
      legal_entity_name: 'Наименование ЮЛ',
      license_number: '№ лицензии',
      inn: 'ИНН',
      ogrn: 'ОГРН',
      legal_address: 'Юридический адрес',
      total_loan_cost: 'Полная стоимость кредита',
      max_loan_term: 'Максимальный срок займа',
      cookieWarning: `Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, пользовательских данных
(сведения о местоположении; тип и версия ОС; тип и версия Браузера; тип устройства и разрешение его экрана;
источник откуда пришел на сайт пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера;
какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес) в целях функционирования сайта,
проведения ретаргетинга и проведения статистических исследований и обзоров. Если вы не хотите, чтобы ваши данные
обрабатывались, покиньте сайт.`,
      cookieAcceptButton: 'Принимаю',
      creditorInfoHeader: 'Информация о кредиторах',
      documentationSource: 'Подробная информация',
      bankButton: 'Оформить',
      advertising: 'Реклама',
      rangeFrom: 'от',
      rangeTo: 'до',
      days_one: 'день',
      days_few: 'дня',
      days_many: 'дней',
      weeks_one: 'неделя',
      weeks_few: 'недели',
      weeks_many: 'недель',
      months_one: 'месяц',
      months_few: 'месяца',
      months_many: 'месяцев',
      years_one: 'год',
      years_few: 'года',
      years_many: 'лет',
      of_days_one: 'дня',
      of_days_few: 'дней',
      of_days_many: 'дней',
      of_weeks_one: 'недели',
      of_weeks_few: 'недель',
      of_weeks_many: 'недель',
      of_months_one: 'месяца',
      of_months_few: 'месяцев',
      of_months_many: 'месяцев',
      of_years_one: 'года',
      of_years_few: 'лет',
      of_years_many: 'лет',
    }
  },
  vi: {
    translation: {
      amount: 'Số Tiền',
      credit_limit: 'Hạn Mức Tín Dụng',
      cash_withdrawal: 'Rút Tiền Mặt',
      interest_rate: 'Lãi Suất',
      age: 'Tuổi',
      annual_income: 'Thu Nhập Hàng Năm',
      term: 'Thời Hạn',
      maintenance: 'Phí Dịch Vụ',
      interest_free_period: 'Số Ngày Miễn Lãi',
      down_payment: 'Khoản Trả Trước',
      legal_entity_name: 'Tên Pháp Nhân',
      license_number: 'Số Giấy Phép',
      inn: 'Mã số thuế',
      ogrn: 'Mã số đăng ký nhà nước chính',
      legal_address: 'Địa Chỉ Pháp Lý',
      total_loan_cost: 'Tổng chi phí của khoản vay',
      max_loan_term: 'Thời Hạn Vay Tối Đa',
      cookieWarning: `Bằng cách tiếp tục sử dụng trang web của chúng tôi, bạn đồng ý với việc xử lý cookie và dữ liệu người dùng
(thông tin vị trí; loại và phiên bản hệ điều hành; loại và phiên bản trình duyệt; loại thiết bị và độ phân giải màn hình;
nguồn gốc người dùng đến trang web; trang web hoặc quảng cáo nào; ngôn ngữ hệ điều hành và trình duyệt;
các trang đã mở và các nút nhấn của người dùng; địa chỉ IP) cho mục đích hoạt động của trang web,
tiếp thị lại và nghiên cứu thống kê. Nếu bạn không muốn dữ liệu của mình
được xử lý, vui lòng rời khỏi trang web.`,
      cookieAcceptButton: 'Chấp Nhận',
      creditorInfoHeader: 'Thông Tin Chủ Nợ',
      documentationSource: 'Thông Tin Chi Tiết',
      bankButton: 'Đăng Ký',
      advertising: 'Quảng cáo',
      rangeFrom: 'từ',
      rangeTo: 'đến',
      days: 'ngày',
      weeks: 'tuần',
      months: 'tháng',
      years: 'năm',
      of_days: 'ngày',
      of_weeks: 'tuần',
      of_months: 'tháng',
      of_years: 'năm',
    }
  }
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ['en', 'ru', 'vi'],
    detection: {
      order: ['querystring', 'navigator', 'localStorage'],
      lookupQuerystring: 'lng',
    },
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

export default i18next;
